"use client"
import { useParams, usePathname } from "next/navigation"
import { Text } from "./Text"
import Link from "next/link"

export const Footer = ({ absolute }: { absolute?: boolean }) => {
  const path = usePathname()

  if (path === "/") return null

  return (
    <footer
      className={`w-full flex items-center justify-center py-2 ${
        absolute ? "absolute bottom-0" : ""
      }`}
    >
      <div className="grid grid-cols-1 md:grid-cols-4 place-items-center gap-2">
        <Text>© {new Date().getFullYear()} Lawn Care Quote Pro</Text>
        <Text>
          <Link
            className="hover:text-spring-green-600 transition"
            href="/privacy"
          >
            Privacy Policy
          </Link>
        </Text>
        <Text>
          <Link
            className="hover:text-spring-green-600 transition"
            href="/terms"
          >
            Terms of Service
          </Link>
        </Text>
        <Text>
          <Link
            className="hover:text-spring-green-600 transition"
            href="/support"
          >
            Contact Us
          </Link>
        </Text>
      </div>
    </footer>
  )
}
